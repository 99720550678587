//格式化日期表达式的映射关系
export const calendarFormatMapper = [{
    beforeYesterday: '[前天]',
    lastDay: '[昨天]',
    sameDay: '[今天]',
    nextDay: '[明天]',
    afterTomorrow: '[后天]',
    sameWeek: '[周]dd',
    nextWeek: '[下周]dd',
    sameElse: 'MM月DD日',
}, {
    beforeYesterday: '[前天]HH:mm',
    afterTomorrow: '[后天]HH:mm',
    sameElse: 'YYYY-MM-DD HH:mm',
}, {
    beforeYesterday: '[前天]',
    sameDay: '[今天] MM月DD日 HH:mm',
    nextDay: '[明天] MM月DD日 HH:mm',
    afterTomorrow: '[后天] MM月DD日 HH:mm',
    sameElse: 'MM月DD日 HH:mm',
}, {
    beforeYesterday: '[前天]',
    lastDay: '[昨天]',
    sameDay: '[今天]M月D日',
    nextDay: '[明天]M月D日',
    afterTomorrow: '[后天]M月D日',
    sameWeek: '[周]ddM月D日',
    sameElse: '[周]ddM月D日',
}];

export function getCalendarFormatterByDays(formatIndex = 0) {
    if (formatIndex == 0) {
        //[其他日期,前天,昨天,今天,明天,后天,本周, 下周]
        //相差 -2天以上=其他日期, -2天=前天, -1天=昨天, 0天=今天, 1天=明天,2天=后天,小于7天=下周, 7天以上=其他日期
        return (targetMoment, nowMoment) => {
            let diff = targetMoment.diff(nowMoment, 'days', true);
            let retVal = diff < -2 ? 'sameElse' :
                diff < -1 ? 'beforeYesterday' :
                diff < 0 ? 'lastDay' :
                diff < 1 ? 'sameDay' :
                diff < 2 ? 'nextDay' :
                diff < 3 ? 'afterTomorrow' :
                targetMoment.week() == nowMoment.week() ? 'sameWeek' :
                targetMoment.week() == (nowMoment.week() + 1) ? 'nextWeek' : 'sameElse';
            return retVal;
        }
    } else if (formatIndex == 1) {
        //[其他日期,前天,昨天,今天,明天,后天]
        //相差 -2天以上=其他日期, -2天=前天, -1天=昨天, 0天=今天, 1天=明天,2天=后天
        return (targetMoment, nowMoment) => {
            let diff = targetMoment.diff(nowMoment, 'days', true);
            let retVal = diff < -2 ? 'sameElse' :
                diff < -1 ? 'beforeYesterday' :
                diff < 0 ? 'lastDay' :
                diff < 1 ? 'sameDay' :
                diff < 2 ? 'nextDay' :
                diff < 3 ? 'afterTomorrow' : 'sameElse';
            return retVal;
        }
    } else if (formatIndex == 2) {
        //[其他日期,前天,昨天,今天,明天,后天,本周]
        //相差 -2天以上=其他日期, -2天=前天, -1天=昨天, 0天=今天, 1天=明天,2天=后天
        return (targetMoment, nowMoment) => {
            let diff = targetMoment.diff(nowMoment, 'days', true);
            let retVal = diff < -2 ? 'sameElse' :
                diff < -1 ? 'beforeYesterday' :
                diff < 0 ? 'lastDay' :
                diff < 1 ? 'sameDay' :
                diff < 2 ? 'nextDay' :
                diff < 3 ? 'afterTomorrow' :
                targetMoment.week() == nowMoment.week() ? 'sameWeek' : 'sameElse';
            return retVal;
        }
    }

}