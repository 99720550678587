const constant = {
    GET_LOGIN_CODE_URL: '/houtai/ct/login/code/get',
    LOGIN_URL: '/houtai/ct/login',
    GET_USER_INFO_URL: '/houtai/ct/user/info/get',
    GET_ORDER_LIST:'/houtai/ct/order/list',
    GET_ORDER_DETAIL:'/houtai/ct/order/get',
    TAKE_ORDER:'/houtai/ct/order/take',
    GET_WAIT_ORDER_LIST:'/houtai/ct/order/wait',
    GET_PROCESSED_ORDER_LIST:'/houtai/ct/order/processed',
    UPLOAD_ORDER_TICKET_CODE:'/houtai/ct/order/upload/ticket/code',
    UNLOCK_SEAT:'/houtai/ct/order/unlock/seat',
    GET_CINEMA_RULE_LIST:'/houtai/ct/cinema/rule/list',
    SET_CINEMA_RULE_STATUS:'/houtai/ct/cinema/rule/status/set',
    UPLOAD_IMAGE:'/houtai/uploadImg'

};

export default constant;
