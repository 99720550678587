export function formatIntToMoney(val) {
    return val ? val / 100 : '0';
}

export function formatDistance(val) {
    if (!val) {
      return ''
    } else {
      const distance = (val / 1000).toFixed(2)
      return distance > 100 ? '>100km' : distance + 'km'
    }
}

export function hideMobile(val) {
    if (Number(val) && String(val).length === 11) {
        let mobile = String(val)
        let reg = /^(\d{3})\d{4}(\d{4})$/
        return mobile.replace(reg, '$1 **** $2')
    } else {
        return val;
    }
}

export function decimalPoint(val) {
    const splitPrice = (new String(val) || '0.00').split('.')
    const numb1 = splitPrice[0]
    var numb2 = '00'
    if (splitPrice.length > 1) {
        numb2 = splitPrice[1]
    }
    return `<span style="font-size:16px;line-height:16px">￥</span><span style="font-size: 22px;line-height: 22px;font-weight: 700;">${numb1}</span>.<span style="font-size:16px;line-height:16px">${numb2}</span>`
}

export function validField(val, type) {
    if (type == 'phoneNumber') {
        let phoneNumberReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (phoneNumberReg.test(val)) {
            return true;
        }
    }

    return false;
}

export function getPlatform() {
    if (/(micromessenger)/i.test(navigator.userAgent)) {
        return 'wx';
    }

    if (/(Android)/i.test(navigator.userAgent)) {
        return 'Android';

    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return 'iOS';
    }
    if ('Win32' === navigator.platform) {
        return 'win';
    }
}
