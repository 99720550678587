<template>
  <div id="app">
    <router-view />
    <van-tabbar route v-show="this.$route.meta.showTab" active-color="#8565bd" inactive-color="#000">
      <van-tabbar-item replace to="/pool" icon="wap-home-o">订单池</van-tabbar-item>
      <van-tabbar-item replace to="/wait" icon="orders-o">待出票</van-tabbar-item>
      <van-tabbar-item replace to="/ticket" icon="home-o">已出票</van-tabbar-item>
      <van-tabbar-item replace to="/mine" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  }
}

</script>

<style lang="scss">
@import './styles/index.scss';

html,
body,
#app {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  margin: 0;
  padding: 0;
  // font-family: "pf regular";
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
}

#app {
  display: flex;
  flex-direction: column;
}
</style>
