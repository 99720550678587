//项目主题样式
import style from '@/styles/project.scss';
//Key常量集合
import storageKey from './storage-key';

import store from '@/store';

const config = {
    const: storageKey,
    style, //全局样式
    remUnit: 37.5,
    requestTimeout: 1000 * 10, //默认请求超时时间
    defaultAppKey: "", //默认渠道appKey
    // defaultAppKey: "wydxl191", //默认渠道appKey
    wxAppId: 'wxf355a4d4ffd4f481',
    // wxAppId: 'wx902070d76162e87a',
    signKey: "D8972106E4349D541C90B0C28C7CD4E7",
    imgBaseUrl: "https://res.dumovie.com/",
    defaultLocation: {
        cityCode: '510100',
        name: '成都',
    },
    defaultCounty: {
        name: '全城'
    },
    baseUrl: {
        dev: "",
        prod: "",
    },
    loadingMsg: '加载中',
    emptyPlaceholder: '　',
    getDefaultPagination: () => {
        return {
            current: 1,
            pages: 0,
            size: 10,
            total: 0,
        }
    },
    getMyOrderPage: () => {
        let authCode = store.state.user.authCode;
        return `https://wx.dumovie.com/app/order/movie/list?auth_code=${authCode}&app_source=h5`;
    }
};

export default Object.assign(config);
