import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
export const routes = [
    {path: "/", redirect: "/pool"},
    {
        path: "/pool",
        name: "Pool",
        component: () => import('@/views/Ticket-Pool'),
        meta: {
            showTab:true
        }
    },
    {
        path: "/wait",
        name: "Wait",
        component: () => import('@/views/Wait-Ticket'),
        meta: {
            showTab:true
        }
    },
    {
        path: "/ticket",
        name: "Ticket",
        component: () => import('@/views/Ticket'),
        meta: {
            showTab:true
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import('@/views/Login'),
        meta: {
            showTab:false
        }
    },
    {
        path: "/mine",
        name: "Mine",
        component: () => import('@/views/Mine'),
        meta: {
            showTab:true
        }
    },
    {
        path: "/order/detail",
        name: "OrderDetail",
        component: () => import('@/views/OrderDetail'),
        meta: {
            showTab:false
        }
    },
    {
        path: "/cinema/rule",
        name: "CinemaRule",
        component: () => import('@/views/RuleDetail'),
        meta: {
            showTab:false
        }
    },
    {
        path: "/404",
        name: "404",
        component: () => import('@/views/404'),
        meta: {
            showTab:false
        }
    },
    {
        path: "*",
        redirect: ()=>{
            return '404'
        },
        meta: {
            showTab:false
        }
    },
];

const createRouter = () => new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router;
