import axios from "../axios";
import baseConstant from "../constants/base-constant";

const request = {
  getWxConfig(data = {}) {
    const url = baseConstant.REQ_WX_CONFIG;
    return axios.request({
      url,
      data,
    });
  },
  getAppKeyInfo(data) {
    const url = baseConstant.REQ_APPKEY_INFO_GET;
    return axios.request({
      url,
      data,
    });
  },
  loginByVerify(data = {}) {
    let url = baseConstant.REQ_AUTH_LOGIN_BY_VERIFY;
    return axios.request({
      url,
      data,
    });
  },
  getWxOpenId(data = {}) {
    let url = baseConstant.REQ_WEIXIN_OPEN_ID_GET;
    return axios.request({
      url,
      data,
    });
  },
  getMemberInfo(data = {}) {
    let url = baseConstant.REQ_MEMBER_INFO_GET;
    return axios.request({
      url,
      data,
    });
  },
  getUserInfo(data = {}) {
    let url = baseConstant.REQ_AUTH_USER_INFO;
    return axios.request({
      url,
      data,
    });
  },
  couponVerifyUsing(data = {}) {
    let url = baseConstant.REQ_AUTH_COUPON_VERIFY_USING;
    return axios.request({
      url,
      data,
    });
  },
  couponVerifyList(data = {}) {
    let url = baseConstant.REQ_AUTH_COUPON_VERIFY_LIST;
    return axios.request({
      url,
      data,
    });
  },
  couponVerifyCancel(data = {}) {
    let url = baseConstant.REQ_AUTH_COUPON_VERIFY_CANCEL;
    return axios.request({
      url,
      data,
    });
  },
  getCouponInfo(data = {}) {
    let url = baseConstant.REQ_AUTH_COUPON_INFO;
    return axios.request({
      url,
      data,
    });
  },

};

export default request;
