import cookies from "vue-cookies";
import config from "@/config";

export default {
  state: {
    title: "",
    env_mode: cookies.get(config.const.KEY_ENV_MODE),
    appKey: cookies.get(config.const.KEY_APP_KEY),
    appKeyName: "",
    appContact: "",
    txId: cookies.get(config.const.KEY_TX_ID),
    platformType: {
      win: false,
      wx: false,
      Android: false,
      iOS: false,
    },
    positionFlag: false,
  },
  getters: {
    env_mode(state) {
      return state.env_mode;
    },
    appKey(state) {
      return state.appKey ? state.appKey : config.defaultAppKey;
    },
    appKeyName(state) {
      return state.appKeyName;
    },
    appContact(state) {
      return state.appContact;
    },
    txId(state) {
      return state.txId || void 0;
    },
    platform(state) {
      let platform = Object.keys(state.platformType).filter(
        (key) => state.platformType[key] === true
      );
      return platform && platform.length > 0 ? platform[0] : void 0;
    },
  },
  mutations: {
    setEnvMode(state, env_mode) {
      state.env_mode = env_mode;
      env_mode
        ? cookies.set(config.const.KEY_ENV_MODE, env_mode, "0")
        : cookies.remove(config.const.KEY_ENV_MODE);
    },
    setTitle(state, title) {
      state.title = title;
    },
    setAppKey(state, appKey) {
      state.appKey = appKey;
      appKey
        ? cookies.set(config.const.KEY_APP_KEY, appKey, "0")
        : cookies.remove(config.const.KEY_APP_KEY);
    },
    setAppKeyName(state, appKeyName) {
      state.appKeyName = appKeyName;
    },
    setAppContact(state, appContact) {
      state.appContact = appContact;
    },
    setTxId(state, txId) {
      state.txId = txId;
      cookies.set(config.const.KEY_TX_ID, txId, "0");
    },
    setPlatformType(state, platform) {
      state.platformType[platform] = true;
    },
  },
};
