const defaultCookieKey = {
    KEY_ENV_MODE: 'ENV_MODE',
    KEY_APP_KEY: 'APP_KEY',
    KEY_TX_ID: 'TX_ID',
    KEY_MAP_POSITION: 'MAP_POSITION',
    KEY_VERIFY_TIME: 'VERIFY_TIME',
    KEY_SELECTED_SEAT_MAP: 'SELECTED_SEAT_MAP',
    KEY_AUTH_CODE: 'AUTH_CODE',
    KEY_LOGIN_PHONE: 'LOGIN_PHONE',
    KEY_WX_OPEN_ID: 'WX_OPEN_ID',
}

export default (() => {
    let prefix = 'PDD_';
    let cookieKey = {};
    Object.keys(defaultCookieKey).forEach((key) => {
        cookieKey[key] = prefix + defaultCookieKey[key];
    });
    return cookieKey;
})();
