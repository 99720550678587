import config from "@/config";
import cookies from "vue-cookies";
import service from "@/api-service";
import router from "@/router";


export default {
  state: {
    userInfo: {},
    hasGetUserInfo: false,
    loginPhone: cookies.get(config.const.KEY_LOGIN_PHONE),
    verifyTime: cookies.get(config.const.KEY_VERIFY_TIME),
    authCode: cookies.get(config.const.KEY_AUTH_CODE),
    openId: cookies.get(config.const.KEY_WX_OPEN_ID),
  },
  getters: {
    authCode(state) {
      return state.authCode;
    },
    loginPhone(state) {
      return state.loginPhone || void 0;
    },
    verifyTime(state) {
      return state.verifyTime || void 0;
    },
    openId(state) {
      return state.openId || void 0;
    },
    hasGetUserInfo(state) {
      return state.hasGetUserInfo;
    },
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setVerifyTime(state, dateTime) {
      state.verifyTime = dateTime;
      dateTime
        ? cookies.set(
            config.const.KEY_VERIFY_TIME,
            dateTime,
            new Date(dateTime)
          )
        : cookies.remove(config.const.KEY_VERIFY_TIME);
    },
    setLoginPhone(state, loginPhone) {
      state.loginPhone = loginPhone;
      loginPhone === false
        ? cookies.remove(config.const.KEY_LOGIN_PHONE)
        : cookies.set(config.const.KEY_LOGIN_PHONE, loginPhone, "7D");
    },
    setAuthCode(state, authCode) {
      state.authCode = authCode;
      cookies.set(config.const.KEY_AUTH_CODE, authCode, "7D");
    },
    removeAuthCode(state){
      state.authCode='';
      cookies.remove(config.const.KEY_AUTH_CODE)
    },
    setOpenId(state, openId) {
      state.openId = openId;
      openId === false
        ? cookies.remove(config.const.KEY_WX_OPEN_ID)
        : cookies.set(config.const.KEY_WX_OPEN_ID, openId, "7D");
    },
  },
  actions: {
    sendVerifyCode({ commit }, mobile) {
      return new Promise((resolve, reject) => {
        service
          .getLoginCode({ mobile })
          .then((res) => {
            let currentDate = new Date();
            commit("setVerifyTime", currentDate.getTime() + 1000 * 60 * 1);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loginByVerify({ rootGetters, commit },
      { mobile, verifyCode }
    ) {
      return new Promise((resolve, reject) => {
        service
          .login({ mobile, code: verifyCode })
          .then((res) => {
            let token = res.token;
            commit("setAuthCode", token);
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    logout({ state, commit }) {
      return new Promise((resolve) => {
        commit("setUserInfo", {});
        commit("removeAuthCode");
        commit("setLoginPhone", false);
        commit("setOpenId", false);
        state.hasGetUserInfo = false;
        router.push({
          name: 'login',
        });
        resolve();
      });
    },
    getUserInfo({ state, commit }) {
      return new Promise((resolve,reject) => {
        service.getUserInfo().then((res) => {
          commit("setUserInfo", {
            id: res.id,
            nickName: res.username,
            mobile: res.mobile,
          });
          state.hasGetUserInfo = true;
          resolve();
        }).catch(error => {
          reject(error)
        })
      });
    },
    resetToken({ state, commit }) {
      return new Promise((resolve) => {
        commit("setUserInfo", {});
        commit("removeAuthCode");
        commit("setLoginPhone", false);
        commit("setOpenId", false);
        state.hasGetUserInfo = false;
        resolve();
      });
    },
  },
};
