import axios from "../axios";
import apiConstant from "../constants/api-constant";

const request = {
  getLoginCode(data = {}) {
    const url = apiConstant.GET_LOGIN_CODE_URL;
    return axios.request({
      url,
      data,
    });
  },

  login(data = {}) {
    const url = apiConstant.LOGIN_URL;
    return axios.request({
      url,
      data,
    });
  },
  getUserInfo(data = {}) {
    const url = apiConstant.GET_USER_INFO_URL;
    return axios.request({
      url
    });
  },
  getOrderList(data = {}) {
    const url = apiConstant.GET_ORDER_LIST;
    return axios.request({
      url,
      data
    });
  },
  getOrderDetail(data = {}) {
    const url = apiConstant.GET_ORDER_DETAIL;
    return axios.request({
      url,
      data
    });
  },
  takeOrder(data = {}) {
    const url = apiConstant.TAKE_ORDER;
    return axios.request({
      url,
      data
    });
  },
  getWaitOrderList(data = {}) {
    const url = apiConstant.GET_WAIT_ORDER_LIST;
    return axios.request({
      url,
      data
    });
  },
  getProcessedOrderList(data = {}) {
    const url = apiConstant.GET_PROCESSED_ORDER_LIST;
    return axios.request({
      url,
      data
    });
  },
  uploadOrderTicketCode(data = {}) {
    const url = apiConstant.UPLOAD_ORDER_TICKET_CODE;
    return axios.request({
      url,
      data
    });
  },
  unlockOrder(data = {}) {
    const url = apiConstant.UNLOCK_SEAT;
    return axios.request({
      url,
      data
    });
  },
  getCinemaRuleList(data = {}) {
    const url = apiConstant.GET_CINEMA_RULE_LIST;
    return axios.request({
      url,
      data
    });
  },
  setCinemaRuleStatus(data = {}) {
    const url = apiConstant.SET_CINEMA_RULE_STATUS;
    return axios.request({
      url,
      data
    });
  },
  uploadImage(data = {}) {
    const url = apiConstant.UPLOAD_IMAGE;
    return axios.request({
      url,
      data,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
};

export default request;
