import { routes } from '@/router';
import router from '@/router';
import store from '@/store';
import $common from './common-utils';
import { dateFormatter, getCalendar, momentFormatter } from '@/libs/date-utils';

export function goPage(name, params = {}, replace = false) {
    if (name == -1) {
        router.back();
        // console.log(router);
        // console.log(router.history);
        // let pending = router.history.pending;
        // if (pending) {
        //     router.push({
        //         path: pending.fullPath
        //     });
        // }
        return;
    }
    console.log('name :>> ', name);
    const fullRoutes = getRoutes(routes) // 递归列出多级路由
    let toPageArr = fullRoutes.filter((item) => item.name === name);
    if (toPageArr && toPageArr.length > 0) {
        let toPage = toPageArr[0];
        if (!replace) {
            router.push({
                name: toPage.name,
                query: params
            })
        } else {
            router.replace({
                name: toPage.name,
                query: params
            })
        }
    }
}

const getRoutes = (routes) => {
  let routesList = []
    routes.map(item => {
      if(item.children) {
        routesList.push(item)
        routesList.push(...getRoutes(item.children))
      } else {
        routesList.push(item)
      }
    })
  return routesList
}

export default {
    install(Vue) {
        Vue.prototype.$goPage = goPage;
        Vue.prototype.$common = $common;
        Vue.prototype.$dateFormatter = dateFormatter;
        Vue.prototype.$momentFormatter = momentFormatter;
        Vue.prototype.$getCalendar = getCalendar;
        Vue.prototype.$documentTitle = (title) => {
            // document.title = title;
            // 永久显示导航栏，避免和微信内部标题重复
            // TODO 确认后去除这句，直接在vue.config.js写死
            document.title = '票蛋蛋电影票'
            store.commit('setTitle', title);
        }
    }
}
